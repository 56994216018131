
@font-face{
    font-family:"NanumMyeongjoR";
    src:url('../fonts/NanumMyeongjoR.eot');
    src:url('../fonts/NanumMyeongjoR.eot?#iefix') format('embedded-opentype'),
    url('../fonts/NanumMyeongjoR.woff') format('woff'),
    url('../fonts/NanumMyeongjoR.ttf') format('truetype');
    src:local('?'), url('../fonts/NanumMyeongjoR.woff') format('woff');
}

@font-face{
    font-family:"NanumMyeongjoR";
    src:url('../fonts/NanumMyeongjoR.eot');
    src:url('../fonts/NanumMyeongjoR.eot?#iefix') format('embedded-opentype'),
    url('../fonts/NanumMyeongjoR.woff') format('woff'),
    url('../fonts/NanumMyeongjoR.ttf') format('truetype');
    src:local('?'), url('../fonts/NanumMyeongjoR.woff') format('woff');
}

@font-face{
    font-family:"NanumMyeongjoEB";
    src:url('../fonts/NanumMyeongjoEB.eot');
    src:url('../fonts/NanumMyeongjoEB.eot?#iefix') format('embedded-opentype'),
    url('../fonts/NanumMyeongjoEB.woff') format('woff'),
    url('../fonts/NanumMyeongjoEB.ttf') format('truetype');
    src:local('?'), url('../fonts/NanumMyeongjoEB.woff') format('woff');
}

@font-face {
    font-family: 'Nanum Myeongjo';
    font-style: normal;
    font-weight: 400;
    src:url('../fonts/NanumMyeongjo.ttf');
}

@font-face {
    font-family: 'Nanum Myeongjo';
    font-style: normal;
    font-weight: 700;
    src:url('../fonts/NanumMyeongjoBold.ttf');
}


@font-face {
    font-family: 'Nanum Myeongjo';
    font-style: normal;
    font-weight: 800;
    src:url('../fonts/NanumMyeongjoExtraBold.ttf');
}

@font-face {
    font-family: 'Noto Sans CJK KR';
    font-style: normal;
    font-weight: 100;
    src: url('../fonts/NotoSansCJKkr-Thin.otf');
}
@font-face {
    font-family: 'Noto Sans CJK KR';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/NotoSansCJKkr-Light.otf');
}
@font-face {
    font-family: 'Noto Sans CJK KR';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/NotoSansCJKkr-Regular.otf');
}
@font-face {
    font-family: 'Noto Sans CJK KR';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/NotoSansCJKkr-Medium.otf');
}
@font-face {
    font-family: 'Noto Sans CJK KR';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/NotoSansCJKkr-Bold.otf');
}
@font-face {
    font-family: 'Noto Sans CJK KR';
    font-style: normal;
    font-weight: 900;
    src: url('../fonts/NotoSansCJKkr-Black.otf');
}
