@charset "utf-8";
@import url('https://fonts.googleapis.com/earlyaccess/nanumgothic.css');

*{
	font-family: "NotoSans-Regular",  sans-serif;
	font-weight:normal;
	font-size:14px;
	letter-spacing:0em;
}

@font-face{
	font-family:"NotoSans-Light";
	src:url('../fonts/NotoSans-Light.eot');
	src:url('../fonts/NotoSans-Light.eot?#iefix') format('embedded-opentype'),
	url('../fonts/NotoSans-Light.woff') format('woff'),
	url('../fonts/NotoSans-Light.otf') format('opentype');
	src:local('?'), url('../fonts/NotoSans-Light.woff') format('woff');
}

@font-face{
	font-family:"NotoSans-Medium";
	src:url('../fonts/NotoSans-Medium.eot');
	src:url('../fonts/NotoSans-Medium.eot?#iefix') format('embedded-opentype'),
	url('../fonts/NotoSans-Medium.woff') format('woff'),
	url('../fonts/NotoSans-Medium.otf') format('opentype');
	src:local('?'), url('../fonts/NotoSans-Medium.woff') format('woff');
}

@font-face{
	font-family:"NotoSans-Bold";
	src:url('../fonts/NotoSans-Bold.eot');
	src:url('../fonts/NotoSans-Bold.eot?#iefix') format('embedded-opentype'),
	url('../fonts/NotoSans-Bold.woff') format('woff'),
	url('../fonts/NotoSans-Bold.otf') format('opentype');
	src:local('?'), url('../fonts/NotoSans-Bold.woff') format('woff');
}

@font-face{
	font-family:"NotoSans-Black";
	src:url('../fonts/NotoSans-Black.eot');
	src:url('../fonts/NotoSans-Black.eot?#iefix') format('embedded-opentype'),
	url('../fonts/NotoSans-Black.woff') format('woff'),
	url('../fonts/NotoSans-Black.otf') format('opentype');
	src:local('?'), url('../fonts/NotoSans-Black.woff') format('woff');
}

@font-face{
	font-family:"NanumMyeongjoR";
	src:url('../fonts/NanumMyeongjoR.eot');
	src:url('../fonts/NanumMyeongjoR.eot?#iefix') format('embedded-opentype'),
	url('../fonts/NanumMyeongjoR.woff') format('woff'),
	url('../fonts/NanumMyeongjoR.ttf') format('truetype');
	src:local('?'), url('../fonts/NanumMyeongjoR.woff') format('woff');
}

@font-face{
	font-family:"NanumMyeongjoEB";
	src:url('../fonts/NanumMyeongjoEB.eot');
	src:url('../fonts/NanumMyeongjoEB.eot?#iefix') format('embedded-opentype'),
	url('../fonts/NanumMyeongjoEB.woff') format('woff'),
	url('../fonts/NanumMyeongjoEB.ttf') format('truetype');
	src:local('?'), url('../fonts/NanumMyeongjoEB.woff') format('woff');
}

.vis_title{position:absolute; top:365px; left: calc(50% - 295px); color:#fff;  font-size:62px; z-index:10; font-family:'NanumMyeongjoR';}

.clock_box {
	position: absolute;
	width: 600px;
	z-index: 2;
	margin-top:85px;
}
/* -------------- Clock -------------- */
.clock{
	position:relative;
	border-radius:436em;
	border:5px solid rgba(255, 255, 255, 0.3);
	height:436px;
	width: 436px;
	position: relative;
	top: 28%;
	top: -webkit-calc(50% - 43px);
	top: calc(50% - 43px);
	left: 35%;
	left: -webkit-calc(50% - 218px);
	left: calc(50% - 218px);
	z-index:1;
}

.clock:before{
	content: "";
	position: absolute;
	background-color: #fff;
	top:75px;
	left: 48%;
	height:157px;
	width: 6px;
	border-radius: 5px;
	-webkit-transform-origin: 50% 94%;
			transform-origin: 50% 94%;
	-webkit-animation: ptAiguille 3s linear infinite;
			animation: ptAiguille 3s linear infinite;
	z-index:1;
}

.clock:after{
	content: "";
	position: absolute;
	background-color: #fff;
	top:128px;
	left: 48%;
	height:95px;
	width:12px;
	-webkit-transform-origin: 50% 97%;
			transform-origin: 50% 97%;
	-webkit-animation: grdAiguille 8s linear infinite;
			animation: grdAiguille 8s linear infinite;
	z-index:1;

}

.clock_center{position:absolute; top:50%; left:50%; width:33px; height:33px; margin:-10px 0 0 -22px; background:#fff; border-radius:3em }

@-webkit-keyframes grdAiguille{
	0%{-webkit-transform:rotate(0deg);}
	100%{-webkit-transform:rotate(360deg);}
}

@keyframes grdAiguille{
	0%{transform:rotate(0deg);}
	100%{transform:rotate(360deg);}
}


@-webkit-keyframes ptAiguille{
	0%{-webkit-transform:rotate(0deg);}
	100%{-webkit-transform:rotate(360deg);}
}

@keyframes ptAiguille{
	0%{transform:rotate(0deg);}
	100%{transform:rotate(360deg);}
}


@media (max-width: 1279px) and (min-width: 768px){
	.clock_box {
		width: 300px;
		margin-top: 80px;
	}
	.clock{
		height:336px;
		width: 336px;
		top: -webkit-calc(50% - 43px);
		top: calc(50% - 43px);
		left: -webkit-calc(50% - 168px);
		left: calc(50% - 168px);
	}
	.clock:before{
		top:32px;
		left: 49%;
		height:140px;
	}

	.clock:after{
		top:70px;
	}

	.clock_center{
		top:50%;
		left:50%;
		width:30px;
		height:30px;
		margin:-15px;
	}

	.vis_title{
		text-align: center;
		top:300px;
		left: calc(50% - 159px);
		font-size:58px;
		line-height: 120%;
	}

}

@media (max-width: 655px) and (min-width: 0px) {
	.clock_box {
		width: 170px;
		margin-top: 40px;
	}
	.clock{
		height:250px;
		width: 250px;
		top: -webkit-calc(50% - 43px);
		top: calc(50% - 43px);
		left: -webkit-calc(50% - 125px);
		left: calc(50% - 125px);
	}
	.clock:before{
		top:27px;
		left: 49%;
		height:100px;
	}

	.clock:after{
		top:44px;
		height:80px;
		width:10px;
		-webkit-transform-origin: 50% 95%;
		transform-origin: 50% 95%;
	}

	.clock_center{
		top:50%;
		left:50%;
		width:30px;
		height:30px;
		margin:-15px;
	}

	.vis_title{
		text-align: center;
		top:200px;
		left: calc(50% - 85px);
		font-size:30px;
	}

}


/* //-------------- Clock -------------- */
