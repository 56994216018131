@import url(https://fonts.googleapis.com/earlyaccess/nanumgothic.css);
@charset "utf-8";

*{
	font-family: "NotoSans-Regular",  sans-serif;
	font-weight:normal;
	font-size:14px;
	letter-spacing:0em;
}

@font-face{
	font-family:"NotoSans-Light";
	src:url(/static/media/NotoSans-Light.516f7bce.eot);
	src:url(/static/media/NotoSans-Light.516f7bce.eot?#iefix) format('embedded-opentype'),
	url(/static/media/NotoSans-Light.1214e1a5.woff) format('woff'),
	url(/static/media/NotoSans-Light.ec7f2349.otf) format('opentype');
	src:local('?'), url(/static/media/NotoSans-Light.1214e1a5.woff) format('woff');
}

@font-face{
	font-family:"NotoSans-Medium";
	src:url(/static/media/NotoSans-Medium.d742f671.eot);
	src:url(/static/media/NotoSans-Medium.d742f671.eot?#iefix) format('embedded-opentype'),
	url(/static/media/NotoSans-Medium.e892f21f.woff) format('woff'),
	url(/static/media/NotoSans-Medium.2a96bfa2.otf) format('opentype');
	src:local('?'), url(/static/media/NotoSans-Medium.e892f21f.woff) format('woff');
}

@font-face{
	font-family:"NotoSans-Bold";
	src:url(/static/media/NotoSans-Bold.97724077.eot);
	src:url(/static/media/NotoSans-Bold.97724077.eot?#iefix) format('embedded-opentype'),
	url(/static/media/NotoSans-Bold.ba396a9f.woff) format('woff'),
	url(/static/media/NotoSans-Bold.0476118d.otf) format('opentype');
	src:local('?'), url(/static/media/NotoSans-Bold.ba396a9f.woff) format('woff');
}

@font-face{
	font-family:"NotoSans-Black";
	src:url(/static/media/NotoSans-Black.a1dc5ffd.eot);
	src:url(/static/media/NotoSans-Black.a1dc5ffd.eot?#iefix) format('embedded-opentype'),
	url(/static/media/NotoSans-Black.f2e40ede.woff) format('woff'),
	url(/static/media/NotoSans-Black.0583fd5d.otf) format('opentype');
	src:local('?'), url(/static/media/NotoSans-Black.f2e40ede.woff) format('woff');
}

@font-face{
	font-family:"NanumMyeongjoR";
	src:url(/static/media/NanumMyeongjoR.bb3ef02d.eot);
	src:url(/static/media/NanumMyeongjoR.bb3ef02d.eot?#iefix) format('embedded-opentype'),
	url(/static/media/NanumMyeongjoR.995e35f8.woff) format('woff'),
	url(/static/media/NanumMyeongjoR.cc9ddbcb.ttf) format('truetype');
	src:local('?'), url(/static/media/NanumMyeongjoR.995e35f8.woff) format('woff');
}

@font-face{
	font-family:"NanumMyeongjoEB";
	src:url(/static/media/NanumMyeongjoEB.1a95440f.eot);
	src:url(/static/media/NanumMyeongjoEB.1a95440f.eot?#iefix) format('embedded-opentype'),
	url(/static/media/NanumMyeongjoEB.2d1bcaf6.woff) format('woff'),
	url(/static/media/NanumMyeongjoEB.992b594f.ttf) format('truetype');
	src:local('?'), url(/static/media/NanumMyeongjoEB.2d1bcaf6.woff) format('woff');
}

.vis_title{position:absolute; top:365px; left: calc(50% - 295px); color:#fff;  font-size:62px; z-index:10; font-family:'NanumMyeongjoR';}

.clock_box {
	position: absolute;
	width: 600px;
	z-index: 2;
	margin-top:85px;
}
/* -------------- Clock -------------- */
.clock{
	position:relative;
	border-radius:436em;
	border:5px solid rgba(255, 255, 255, 0.3);
	height:436px;
	width: 436px;
	position: relative;
	top: 28%;
	top: calc(50% - 43px);
	left: 35%;
	left: calc(50% - 218px);
	z-index:1;
}

.clock:before{
	content: "";
	position: absolute;
	background-color: #fff;
	top:75px;
	left: 48%;
	height:157px;
	width: 6px;
	border-radius: 5px;
	-webkit-transform-origin: 50% 94%;
			transform-origin: 50% 94%;
	-webkit-animation: ptAiguille 3s linear infinite;
			animation: ptAiguille 3s linear infinite;
	z-index:1;
}

.clock:after{
	content: "";
	position: absolute;
	background-color: #fff;
	top:128px;
	left: 48%;
	height:95px;
	width:12px;
	-webkit-transform-origin: 50% 97%;
			transform-origin: 50% 97%;
	-webkit-animation: grdAiguille 8s linear infinite;
			animation: grdAiguille 8s linear infinite;
	z-index:1;

}

.clock_center{position:absolute; top:50%; left:50%; width:33px; height:33px; margin:-10px 0 0 -22px; background:#fff; border-radius:3em }

@-webkit-keyframes grdAiguille{
	0%{-webkit-transform:rotate(0deg);}
	100%{-webkit-transform:rotate(360deg);}
}

@keyframes grdAiguille{
	0%{-webkit-transform:rotate(0deg);transform:rotate(0deg);}
	100%{-webkit-transform:rotate(360deg);transform:rotate(360deg);}
}


@-webkit-keyframes ptAiguille{
	0%{-webkit-transform:rotate(0deg);}
	100%{-webkit-transform:rotate(360deg);}
}

@keyframes ptAiguille{
	0%{-webkit-transform:rotate(0deg);transform:rotate(0deg);}
	100%{-webkit-transform:rotate(360deg);transform:rotate(360deg);}
}


@media (max-width: 1279px) and (min-width: 768px){
	.clock_box {
		width: 300px;
		margin-top: 80px;
	}
	.clock{
		height:336px;
		width: 336px;
		top: calc(50% - 43px);
		left: calc(50% - 168px);
	}
	.clock:before{
		top:32px;
		left: 49%;
		height:140px;
	}

	.clock:after{
		top:70px;
	}

	.clock_center{
		top:50%;
		left:50%;
		width:30px;
		height:30px;
		margin:-15px;
	}

	.vis_title{
		text-align: center;
		top:300px;
		left: calc(50% - 159px);
		font-size:58px;
		line-height: 120%;
	}

}

@media (max-width: 655px) and (min-width: 0px) {
	.clock_box {
		width: 170px;
		margin-top: 40px;
	}
	.clock{
		height:250px;
		width: 250px;
		top: calc(50% - 43px);
		left: calc(50% - 125px);
	}
	.clock:before{
		top:27px;
		left: 49%;
		height:100px;
	}

	.clock:after{
		top:44px;
		height:80px;
		width:10px;
		-webkit-transform-origin: 50% 95%;
		transform-origin: 50% 95%;
	}

	.clock_center{
		top:50%;
		left:50%;
		width:30px;
		height:30px;
		margin:-15px;
	}

	.vis_title{
		text-align: center;
		top:200px;
		left: calc(50% - 85px);
		font-size:30px;
	}

}


/* //-------------- Clock -------------- */


@font-face{
    font-family:"NanumMyeongjoR";
    src:url(/static/media/NanumMyeongjoR.bb3ef02d.eot);
    src:url(/static/media/NanumMyeongjoR.bb3ef02d.eot?#iefix) format('embedded-opentype'),
    url(/static/media/NanumMyeongjoR.995e35f8.woff) format('woff'),
    url(/static/media/NanumMyeongjoR.cc9ddbcb.ttf) format('truetype');
    src:local('?'), url(/static/media/NanumMyeongjoR.995e35f8.woff) format('woff');
}

@font-face{
    font-family:"NanumMyeongjoR";
    src:url(/static/media/NanumMyeongjoR.bb3ef02d.eot);
    src:url(/static/media/NanumMyeongjoR.bb3ef02d.eot?#iefix) format('embedded-opentype'),
    url(/static/media/NanumMyeongjoR.995e35f8.woff) format('woff'),
    url(/static/media/NanumMyeongjoR.cc9ddbcb.ttf) format('truetype');
    src:local('?'), url(/static/media/NanumMyeongjoR.995e35f8.woff) format('woff');
}

@font-face{
    font-family:"NanumMyeongjoEB";
    src:url(/static/media/NanumMyeongjoEB.1a95440f.eot);
    src:url(/static/media/NanumMyeongjoEB.1a95440f.eot?#iefix) format('embedded-opentype'),
    url(/static/media/NanumMyeongjoEB.2d1bcaf6.woff) format('woff'),
    url(/static/media/NanumMyeongjoEB.992b594f.ttf) format('truetype');
    src:local('?'), url(/static/media/NanumMyeongjoEB.2d1bcaf6.woff) format('woff');
}

@font-face {
    font-family: 'Nanum Myeongjo';
    font-style: normal;
    font-weight: 400;
    src:url(/static/media/NanumMyeongjo.7118f418.ttf);
}

@font-face {
    font-family: 'Nanum Myeongjo';
    font-style: normal;
    font-weight: 700;
    src:url(/static/media/NanumMyeongjoBold.77fcbc43.ttf);
}


@font-face {
    font-family: 'Nanum Myeongjo';
    font-style: normal;
    font-weight: 800;
    src:url(/static/media/NanumMyeongjoExtraBold.1671ca26.ttf);
}

@font-face {
    font-family: 'Noto Sans CJK KR';
    font-style: normal;
    font-weight: 100;
    src: url(/static/media/NotoSansCJKkr-Thin.c5e62551.otf);
}
@font-face {
    font-family: 'Noto Sans CJK KR';
    font-style: normal;
    font-weight: 300;
    src: url(/static/media/NotoSansCJKkr-Light.528e085c.otf);
}
@font-face {
    font-family: 'Noto Sans CJK KR';
    font-style: normal;
    font-weight: 400;
    src: url(/static/media/NotoSansCJKkr-Regular.3e8a3d88.otf);
}
@font-face {
    font-family: 'Noto Sans CJK KR';
    font-style: normal;
    font-weight: 500;
    src: url(/static/media/NotoSansCJKkr-Medium.a89c0e76.otf);
}
@font-face {
    font-family: 'Noto Sans CJK KR';
    font-style: normal;
    font-weight: 700;
    src: url(/static/media/NotoSansCJKkr-Bold.9b1db29f.otf);
}
@font-face {
    font-family: 'Noto Sans CJK KR';
    font-style: normal;
    font-weight: 900;
    src: url(/static/media/NotoSansCJKkr-Black.c08d1a40.otf);
}

